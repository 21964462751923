//FECHA_FACTURA_PROVEEDOR
const backToFront = (data = [], type) => {
  let result = {
    meta: data.meta,
    emb: [],
  };
  if (type !== "MAR") {
    for (let index = 0; index < data.data.length; index++) {
      let nov = {};
      nov.idProfit = data.data[index]["ID_PROFIT"];
      nov.referencia = data.data[index]["ID_FILE_VALITON"];
      nov.concepto = data.data[index]["CONCEPTO"];
      nov.cantidad = data.data[index]["CANTIDAD"];
      nov.precio = data.data[index]["PRECIO"];
      nov.divisa = data.data[index]["DIVISA"];
      nov.tasaRet = Number(data.data[index]["RET"]);
      nov.tasaIva = Number(data.data[index]["IVA"]);
      nov.monto = Number(data.data[index]["MONTO"]);
      nov.idProveedor = data.data[index]["ID_PROVEEDOR"];
      nov.seleccionado = data.data[index]["SELECCIONADO"];
      nov.tipo = data.data[index]["COSTO_VENTA"];
      nov.idFacturaWeb = data.data[index]["IDFACTURAWEB"];
      nov.pagado = data.data[index]["PAGADO"];
      nov.fechaPagado = data.data[index]["FECHA_PAGO"];
      nov.facturaProveedor = data.data[index]["NUMERO_FACTURA_PROVEEDOR"];
      nov.facturaFactura = data.data[index]["FECHA_FACTURA_PROVEEDOR"];
      nov.iva = Number((nov.tasaIva * nov.monto).toFixed(2));
      nov.ret = Number((nov.tasaRet * nov.monto).toFixed(2));
      nov.total = Number((nov.monto + nov.iva - nov.ret).toFixed(2));
      result.emb.push(nov);
    }
  } else {
    for (let index = 0; index < data.data.length; index++) {
      let nov = {};
      nov.idProfit = data.data[index]["__ID_PROFNLOST"];
      nov.referencia = data.data[index]["FILE_VALITON"];
      nov.concepto = data.data[index]["CONCEPTO"];
      nov.cantidad = 1;
      nov.precio = data.data[index]["SubtotalDivisaOriginalPortalProveedores"];
      nov.divisa = data.data[index]["DIVISA"];
      nov.tasaRet = Number(data.data[index]["RETENCION"]);
      nov.tasaIva = Number(data.data[index]["IVA"]);
      nov.monto = Number(
        data.data[index]["SubtotalDivisaOriginalPortalProveedores"]
      );
      nov.idProveedor = data.data[index]["_ID_proveedor"];
      nov.seleccionado = data.data[index]["SELECCIONADO"];
      nov.tipo = data.data[index]["TIPO_COSTO_VENTA"];
      nov.idFacturaWeb = data.data[index]["IDFACTURAWEB"];
      nov.pagado = data.data[index]["PAGADO"];
      nov.fechaPagado = data.data[index]["FECHA DE PAGO"];
      nov.facturaProveedor = data.data[index]["FACTURA PROVEEDOR"];
      nov.facturaFactura = data.data[index]["FECHA_FACTURA_PROVEEDOR"];
      nov.iva = Number((nov.tasaIva * nov.monto).toFixed(2));
      nov.ret = Number((nov.tasaRet * nov.monto).toFixed(2));
      nov.total = Number((nov.monto + nov.iva - nov.ret).toFixed(2));
      result.emb.push(nov);
    }
  }
  return result;
};

const frontToBack = (data = []) => {
  let reMeta = data.meta;
  let result = {
    ter: {
      meta: [],
      data: [],
    },
    mar: {
      meta: [],
      data: [],
    },
  };
  data.data.forEach((item, index) => {
    if (item.tref !== "MAR") {
      result.ter.meta.push(reMeta[index]);
      result.ter.data.push({
        ID_PROVEEDOR: item["idProveedor"],
        SELECCIONADO: item["seleccionado"],
        IDFACTURAWEB: item["idFacturaWeb"],
        NUMERO_FACTURA_PROVEEDOR: item["facturaProveedor"],
        FECHA_FACTURA_PROVEEDOR: convertData(item["facturaFactura"]),
        PAGADO: item["pagado"],
        FECHA_PAGO: convertData(item["fechaPagado"]),
      });
    } else {
      result.mar.meta.push(reMeta[index]);
      result.mar.data.push({
        _ID_proveedor: item["idProveedor"],
        SELECCIONADO: item["seleccionado"],
        IDFACTURAWEB: item["idFacturaWeb"],
        "FACTURA PROVEEDOR": item["facturaProveedor"],
        PAGADO: item["pagado"],
        FECHA_FACTURA_PROVEEDOR: convertData(item["facturaFactura"]),
        "FECHA DE PAGO": convertData(item["fechaPagado"]),
      });
    }
  });
  return result;
};

const clearFrontToBack = (data = []) => {
  let reMeta = data.meta;
  let result = {
    ter: {
      meta: [],
      data: [],
    },
    mar: {
      meta: [],
      data: [],
    },
  };
  data.data.forEach((item, index) => {
    if (item.tref !== "MAR") {
      result.ter.meta.push(reMeta[index]);
      result.ter.data.push({
        ID_PROVEEDOR: null,
        SELECCIONADO: null,
        IDFACTURAWEB: null,
        NUMERO_FACTURA_PROVEEDOR: null,
        FECHA_FACTURA_PROVEEDOR: null,
        PAGADO: null,
        FECHA_PAGO: null,
      });
    } else {
      result.mar.meta.push(reMeta[index]);
      result.mar.data.push({
        _ID_proveedor: null,
        SELECCIONADO: null,
        IDFACTURAWEB:null,
        "FACTURA PROVEEDOR": null,
        PAGADO: null,
        FECHA_FACTURA_PROVEEDOR: null,
        "FECHA DE PAGO": null,
      });
    }
  });
  return result;
};

const isEmptyOrSpaces = (str) => {
  return str === null || str.match(/^ *$/) !== null;
};
const convertData = (s) => {
  if (!isEmptyOrSpaces(s)) {
    if (s.indexOf("/") > 0) {
      var d = s.split("/");
      let dd = d[0];
      let mm = d[1];
      let aa = d[2];
      return dd + "/" + mm + "/" + aa;
    } else {
      var datePart = s.split("-");
      let year = datePart[0];
      let month = datePart[1];
      let day = datePart[2];
      return day + "/" + month + "/" + year;
    }
  } else {
    return "";
  }
};

export { backToFront, frontToBack,clearFrontToBack };
