/**
 * Agrega el concepto seleccionado al state
 *
 * @param {"concepto"} payload
 */

export const applyConcepto = (payload) => ({
  type: "ADD_CONCEPTO",
  payload,
});

export const deleteConcepto = (payload) => ({
  type: "DELETE_CONCEPTO",
  payload,
});