import React, { useState, useEffect, Fragment } from "react";
import { connect } from "react-redux";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import ReactTagInput from "@pathofdev/react-tag-input";
import BootstrapSwitchButton from "bootstrap-switch-button-react";

import {
  fetchProviders,
  saveUser,
  updateUser,
  cancelEditor,
} from "../actions/usuarios";

import "react-bootstrap-typeahead/css/Typeahead.css";
import "../assets/styles/components/FormModal.css";
import "@pathofdev/react-tag-input/build/index.css";

const Usuario = (props) => {
  const isEmptyOrSpaces = (str) => {
    return str === null || str.match(/^ *$/) !== null;
  };

  const [form, setValues] = useState({
    id: props.id,
    idFProveedor: props.idFProveedor,
    name: props.name,
    username: props.username,
    RFC: props.RFC,
    razonSocial: props.razonSocial,
    email: props.email,
    role: props.role === null ? "USER" : props.role,
    emailNotificaciones: props.emailNotificaciones,
    diasCredito: props.diasCredito,
    dpassword: props.dpassword,
    nacional: props.nacional,
    AA: props.AA,
    contpaqMxn:"",
    contpaqUsd:""
  });
  const [isValid, setValid] = useState(false);
  const [isSearching, setSearch] = useState(false);
  const [load, setLoading] = useState(false);
  const [options, setOptions] = useState([]);
  const [emails, setEmails] = useState(
    props.emailNotificaciones === null ||
      props.emailNotificaciones === undefined
      ? []
      : props.emailNotificaciones.split(";")
  );

  const MySwal = withReactContent(
    Swal.mixin({
      toast: true,
      position: "top-end",
      showConfirmButton: false,
      timer: 1500,
      timerProgressBar: true,
      onOpen: (toast) => {
        toast.addEventListener("mouseenter", Swal.stopTimer);
        toast.addEventListener("mouseleave", Swal.resumeTimer);
      },
    })
  );

  useEffect(() => {
    switch (form.role) {
      case "USER":
        if (
          form.name === undefined ||
          form.name === null ||
          form.name.trim() === "" ||
          form.RFC === undefined ||
          form.RFC === null ||
          form.RFC.trim() === "" ||
          form.razonSocial === undefined ||
          form.razonSocial === null ||
          form.razonSocial.trim() === "" ||
          form.email === undefined ||
          form.email === null ||
          form.email.trim() === "" ||
          form.dpassword === undefined ||
          form.dpassword === null ||
          form.dpassword.trim() === "" ||
          form.role === undefined ||
          form.role === null ||
          form.role.trim() === "" ||
          form.nacional === undefined ||
          form.nacional === null ||
          form.nacional.trim() === ""
        ) {
          setValid(true);
        } else {
          setValid(false);
        }
        break;
      default:
        if (
          form.name === undefined ||
          form.name === null ||
          form.name.trim() === "" ||
          form.email === undefined ||
          form.email === null ||
          form.email.trim() === "" ||
          form.dpassword === undefined ||
          form.dpassword === null ||
          form.dpassword.trim() === "" ||
          form.role === undefined ||
          form.role === null ||
          form.role.trim() === ""
        ) {
          setValid(true);
        } else {
          setValid(false);
        }
        break;
    }
  }, [form, isValid]);

  useEffect(() => {
    let emn = emails.join(";");
    setValues((f) => ({ ...f, emailNotificaciones: emn }));
  }, [emails, setValues]);

  const handleChange = (event) => {
    setValues({
      ...form,
      [event.target.name]: event.target.value,
    });
  };

  const handleFindProvider = (query) => {
    props
      .fetchProviders(
        `ProveedoresApi.json?RFMfind=SELECT%20%2A%20WHERE%20RAZON_SOCIAL%20LIKE%20%27${encodeURIComponent(
          query
        )}%2A%27`
      )
      .then((data) => {
        setOptions(data.data);
      });
  };

  const handleSubmit = () => {
    if (form.nacional !== "NACIONAL" || form.role !== "USER") {
      setValues((f) => ({ ...f, AA: 0 }));
    }
    setLoading(true);
    switch (props.action) {
      case "N":
        props
          .saveUser(form, "register", props)
          .then(() => {
            props.handelHide();
          })
          .catch(() => {
            MySwal.fire({
              icon: "error",
              title: "Error al envial notificacion",
            });
            setLoading(false);
            props.handelHide();
          });
        break;
      case "E":
        props.updateUser(form, "user", props);
        break;
      default:
        break;
    }
  };

  const cbHide = () => {
    props.cancelEditor();
    props.handelHide();
  };

  const handelSelec = ([selectedProvider]) => {
    setSearch(true);
    if (selectedProvider !== undefined) {
      setValues({
        ...form,
        idFProveedor: selectedProvider["ID_PROVEEDOR"],
        razonSocial: selectedProvider["RAZON_SOCIAL"],
        RFC: selectedProvider["RFC"],
        diasCredito: selectedProvider["DIAS_CREDITO"],
        contpaqMxn:selectedProvider["contpaqMxn"],
        contpaqUsd:selectedProvider["contpaqUsd"]
      });
      setSearch(false);
    }
  };

  const validateEmail = (emailToValid) => {
    let mailformat =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    if (emailToValid.match(mailformat)) {
      return true;
    } else {
      MySwal.fire({
        icon: "error",
        title: "Email no valido",
      });
      return false;
    }
  };

  const myRef = React.createRef();
  const handleTagblur = () => {
    let tags = myRef.current;
    let nwarr = tags.props.tags;
    let tagString = tags.state.input;
    if (!isEmptyOrSpaces(tagString)) {
      if (tags.props.validator(tagString)) {
        nwarr.push(tagString);
        tags.props.onChange(nwarr);
        tags.state.input = "";
      }
    }
  };

  return (
    <div className="modalprincipal">
      <div className="submodal">
        <div className="submodalTitle">
          <span>INFORMACION DE USUARIO</span>
        </div>
      </div>
      <div className="modalcontenido">
        <div className="row">
          <div className="col-6">
            <label htmlFor="re">Perfil:</label>
            <select
              name="role"
              type="text"
              className="form-control"
              value={form.role || ""}
              onChange={handleChange}
            >
              <option></option>
              <option value="DEVELOPER" disabled>
                DEVELOPER
              </option>
              <option value="ADMIN">ADMIN</option>
              <option value="OPERACIONES">OPERACIONES</option>
              <option value="USER">USER</option>
            </select>
          </div>
          <div className="col-4">
            <label htmlFor="re">Nacionalidad:</label>
            <select
              name="nacional"
              type="text"
              className="form-control"
              value={form.nacional || ""}
              onChange={handleChange}
              disabled={form.role !== "USER"}
            >
              <option></option>
              <option value="NACIONAL">NACIONAL</option>
              <option value="EXTRANJERO">EXTRANJERO</option>
            </select>
          </div>
          <div className="col-2">
            <label htmlFor="switch">AA:</label>
            <div id="switch">
              <BootstrapSwitchButton
                checked={form.AA === 1 ? true : false}
                onlabel="SI"
                offlabel="NO"
                onChange={(checked) => {
                  let val =
                    form.nacional === "NACIONAL" &&
                    form.role === "USER"
                      ? checked
                      : 0;
                  setValues((f) => ({ ...f, AA: val }));
                }}
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-9">
            <label htmlFor="re">Proveedor:</label>
            <AsyncTypeahead
              inputProps={{name:"razonSocial"}}
              isLoading={isSearching}
              id="async-providers"
              options={options}
              onInputChange={(i,e)=>{handleChange(e);handleFindProvider(i)}}
              placeholder={form.razonSocial || "Buscar Proveedor"}
              labelKey={"RAZON_SOCIAL"}
              onChange={(selectedData) => handelSelec(selectedData)}
              disabled={form.role !== "USER"}
              renderMenuItemChildren={(options) => (
                <Fragment>
                  <span>{options["RAZON_SOCIAL"]}</span>
                </Fragment>
              )}
            />
          </div>
          <div className="col-3">
            <label htmlFor="re">Dias Credito:</label>
            <input
              name="diasCredito"
              type="text"
              className="form-control"
              value={form.diasCredito || ""}
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-8">
            <label htmlFor="re">Nombre:</label>
            <input
              name="name"
              type="text"
              className="form-control"
              value={form.name || ""}
              onChange={handleChange}
            />
          </div>
          <div className="col-4">
            <label htmlFor="re">RFC:</label>
            <input
              name="RFC"
              type="text"
              className="form-control"
              value={form.RFC || ""}
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <label htmlFor="re">Email Principal:</label>
            <input
              name="email"
              type="text"
              className="form-control"
              value={form.email || ""}
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-12" onBlur={handleTagblur}>
            <label htmlFor="re">Email Notificaciones:</label>
            <ReactTagInput
              ref={myRef}
              tags={emails}
              name="emails"
              className="form-control"
              placeholder="Agregar Correo"
              removeOnBackspace={true}
              onChange={(newEmail) => {
                setEmails(newEmail.map((x) => x.toLowerCase()));
              }}
              validator={(value) => validateEmail(value)}
            />
          </div>
        </div>
        <div>
          <div className="row">
            <div className="col-6">
              <label htmlFor="re">UserName:</label>
              <input
                name="username"
                className="form-control"
                type="text"
                value={form.username || ""}
                onChange={handleChange}
              />
            </div>
            <div className="col-6">
              <label htmlFor="re">Contraseña:</label>
              <input
                name="dpassword"
                className="form-control"
                type="text"
                value={form.dpassword || ""}
                onChange={handleChange}
              />
            </div>
          </div>
        </div>
      </div>
      <hr />
      <div className="row botonmodal">
        <div className="col-6">
          <button
            className=" btn btn-link cancelar"
            type="button"
            onClick={cbHide}
          >
            Cancelar
          </button>
        </div>
        <div className="col-6">
          {load ? (
            <button className="btn btn-link aceptar" type="button" disabled>
              <span
                className="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              ></span>
              Guardando...
            </button>
          ) : (
            <button
              className="btn btn-link aceptar"
              type="button"
              onClick={handleSubmit}
              disabled={isValid}
            >
              Aceptar
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

const mapStateToprops = (state) => {
  return {
    token: state.session.token,
    providers: state.providers,
  };
};
const mapDispathToProps = {
  saveUser,
  fetchProviders,
  updateUser,
  cancelEditor,
};

export default connect(mapStateToprops, mapDispathToProps)(Usuario);
