import React from "react";

import "../assets/styles/components/noData.css";

const NoData = (props) => {
  return (
    <div id="noDat">
      <div className="fof text-center">
        <h1>NO  DATA :( </h1>
      </div>
    </div>
  );
};

export default NoData;
