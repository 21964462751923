import React from "react";

import "../assets/styles/components/NotFound.css";

const NotFound = (props) => {
  return (
    <div id="main">
      <div className="fof">
        <h1>Error 404</h1>
      </div>
    </div>
  );
};

export default NotFound;
