import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import { logOut, setLang } from "../actions";
import { useTranslation } from "react-i18next";

import gravatar from "../utils/gravatar";

import "../assets/styles/components/Header.css";
import logo from "../assets/static/valitoncorp_logo.png";

const Header = (props) => {
  const { session } = props;
  const [t, i18n] = useTranslation("global");

  const handleLogout = (event) => {
    props.logOut();
  };

  const changueLNG = (lng) =>{
    i18n.changeLanguage(lng);
    props.setLang(lng);
  }

  if (session.token.length > 0) {
    return (
      <header>
        <nav className="navbar navbar-expand-lg fixed-top">
          <a className="navbar-brand" href="https://valitoncorp.com/">
            <img src={logo} alt="" />
          </a>
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarTogglerDemo02"
            aria-controls="navbarTogglerDemo02"
          >
            <span className="navbar-toggler-icon">
              <i className="fas fa-align-justify"></i>
            </span>
          </button>
          <div
            className=" collapse navbar-collapse justify-content-end"
            id="navbarTogglerDemo02"
          >
            <ul className="navbar-nav">
              {session.user.role === "USER" ||
              session.user.role === "DEVELOPER" ? (
                <li className="nav-item">
                  <Link className="nav-link" to="/facturas">
                    {t("nav.registroFacturas")}
                  </Link>
                </li>
              ) : null}
              {session.user.role === "USER" ||
              session.user.role === "DEVELOPER" ? (
                <li className="nav-item dropdown">
                  <a
                    className="nav-link dropdown-toggle"
                    href="#"
                    id="lngDropdown"
                    role="button"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <i className="fa fa-globe"></i>
                    <span style={{paddingLeft:"5px"}}>{t("nav.cambiarIdioma")}</span>
                  </a>
                  <div className="dropdown-menu" aria-labelledby="lngDropdown">
                    <button className="dropdown-item" onClick={()=>changueLNG('es')}>
                      Español
                    </button>
                    <button className="dropdown-item" onClick={()=>changueLNG('en')}>
                      English
                    </button>
                  </div>
                </li>
              ) : null}
              {session.user.role === "ADMIN" ||
              session.user.role === "OPERACIONES" ||
              session.user.role === "DEVELOPER" ? (
                <li className="nav-item">
                  <Link className="nav-link" to="/home">
                    RECEPCION DE FACTURAS
                  </Link>
                </li>
              ) : null}
              {session.user.role === "ADMIN" ||
              session.user.role === "DEVELOPER" ? (
                <li className="nav-item">
                  <Link className="nav-link" to="/program">
                    GENERAL DE FACTURAS
                  </Link>
                </li>
              ) : null}
              {session.user.role === "ADMIN" ||
              session.user.role === "DEVELOPER" ? (
                <li className="nav-item">
                  <Link className="nav-link" to="/usuarios">
                    USUARIOS
                  </Link>
                </li>
              ) : null}
              <li className="d-flex nav-item">
                <Link className="nav-link" onClick={handleLogout} to="/">
                  {t("nav.salir")}
                </Link>
                <div className="imagen">
                  <img
                    src={gravatar(session.user.email)}
                    alt={session.user.name}
                    className="cl"
                  />
                </div>
              </li>
            </ul>
          </div>
        </nav>
      </header>
    );
  } else {
    return (
      <header>
        <nav className="navbar navbar-expand-lg fixed-top">
          <a className="navbar-brand" href="https://valitoncorp.com/">
            <img src={logo} alt="" />
          </a>
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarTogglerDemo02"
            aria-controls="navbarTogglerDemo02"
          >
            <span className="navbar-toggler-icon">
              <i className="fas fa-align-justify"></i>
            </span>
          </button>
          <div
            className=" collapse navbar-collapse justify-content-end"
            id="navbarTogglerDemo02"
          >
            <ul className="navbar-nav">
              <li className="nav-item">
                <a
                  className="nav-link"
                  href="https://valitoncorp.com/about.html"
                >
                  ABOUT US
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link"
                  href="https://valitoncorp.com/service.html"
                >
                  SERVICES
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link"
                  href="https://valitoncorp.com/quote.html"
                >
                  QUOTE ME
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link"
                  href="https://valitoncorp.com/tracking/"
                >
                  CUSTOMER
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link"
                  href="http://proveedores.valitoncorp.com/"
                >
                  PROVIDERS
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link"
                  href="https://valitoncorp.com/contact.php"
                >
                  CONTACT US
                </a>
              </li>
            </ul>
          </div>
        </nav>
      </header>
    );
  }
};

const mapStateToprops = (state) => {
  return {
    session: state.session,
  };
};

const mapDispathToProps = {
  logOut,
  setLang,
};

export default connect(mapStateToprops, mapDispathToProps)(Header);
