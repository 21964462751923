import React from "react";
import { connect } from "react-redux";
import NumberFormat from "react-number-format";
import { Toggle, Modal } from "../utils/modalUtil.jsx";

//importando actions
import { deleteConcepto } from "../actions/profit";
import { applyPago } from "../actions/facturas";
import { frontToBack } from "./../utils/ExchangeFormat";
import FacturasCliente from "./FacturasCliente.jsx";

const ProfitSelected = (props) => {
  
  const delConcepto = (data, meta) => {
    let profitElement = {
      meta: [meta],
      data: [data],
    };

    let call = frontToBack(profitElement);
    if (call.ter !== undefined && call.ter.data.length > 0) {
      call.ter.data[0]["IDFACTURAWEB"] = null;
      call.ter.data[0]["SELECCIONADO"] = null;
      call.ter.data[0]["NUMERO_FACTURA_PROVEEDOR"] = null;
      call.ter.data[0]["FECHA_FACTURA_PROVEEDOR"] = null;
      props.applyPago(call.ter, "ApiProfit.json");
    }
    if (call.mar !== undefined && call.mar.data.length > 0) {
      call.mar.data[0]["IDFACTURAWEB"] = null;
      call.mar.data[0]["SELECCIONADO"] = null;
      call.mar.data[0]["FACTURA PROVEEDOR"] = null;
      call.mar.data[0]["FECHA_FACTURA_PROVEEDOR"] = null;
      props.applyPago(call.mar, "ApiProfitMaritimo.json");
    }
    let payload = [meta.recordID, data.idProfit, data.monto, data.total];
    props.deleteConcepto(payload);
  };

  return (
    <div>
      <h4>Conceptos Relacionados</h4>
      <div className="row">
        <div className="tabla-sm-cal col-12">
          <table className="table table-sm">
            <thead className="titulotabla-sm">
              <tr>
                <th>Referencia</th>
                <th>Concepto</th>
                <th>Divisa</th>
                <th>Monto</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {props.profit.data !== undefined &&
                props.profit.data.map((item, i) => (
                  <tr key={i}>
                    <td className="td-sm text-center">{item.referencia}</td>
                    <td className="td-sm">{item.concepto}</td>
                    <td className="td-sm text-center">{item.divisa}</td>
                    <td className="td-sm text-right">
                      <NumberFormat
                        value={item.monto}
                        displayType={"text"}
                        thousandSeparator={true}
                        prefix={"$"}
                      />
                    </td>
                    <td className="td-sm text-center">
                      <button
                        className="btn btn-link btn-sm"
                        onClick={() => delConcepto(item, props.profit.meta[i])}
                      >
                        <i className="fas fa-trash-alt"></i>
                      </button>
                      <Toggle
                        toggle={(show) => (
                          <button className="btn btn-link" onClick={show}>
                            <i className="far fa-eye"></i>
                          </button>
                        )}
                        content={(hide) => (
                          <Modal>
                            <FacturasCliente handelHide={hide} file={item.referencia} />
                          </Modal>
                        )}
                      />
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

const mapStateToprops = (state) => {
  return {
    profit: state.profit,
    session: state.session,
    factura: state.factura.Factura,
  };
};

const mapDispathToProps = {
  deleteConcepto,
  applyPago,
};

export default connect(mapStateToprops, mapDispathToProps)(ProfitSelected);
