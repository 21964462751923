import axios from "axios";
import { URL } from "../utils/globalConect";

export const loginReques = payload => ({
  type: "LOGIN_REQUEST",
  payload
});

export const setError = payload => ({
  type: "SET_ERROR",
  payload
});

export const sendLogin = (payload, section) => {
  return async dispatch => {
    return axios
      .post(`${URL}${section}`, `json=${encodeURIComponent(JSON.stringify(payload))}`, {
        headers: { "Content-Type": "application/x-www-form-urlencoded" }
      })
      .then(({ data }) => {
        data.lng = payload.lng;
        dispatch(loginReques(data));
        return true;
      })
      .catch(function(error) {
        let err = [error][0].response.data;
        dispatch(setError(err));
        return err;
      });
  };
};

export const logOut = payload => {
  return dispatch =>
    dispatch({
      type: "LOGOUT_REQUEST",
      payload
    });
};

export const setLang = payload =>{
  return dispatch =>
  dispatch({
    type: "SETLNG_REQUEST",
    payload
  });
}
