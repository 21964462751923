import React from "react";
import NumberFormat from "react-number-format";
import { useTranslation } from "react-i18next";
import "../assets/styles/components/TablePageSM.css";

const TakenXML = (objXml) => {
  const [t, i18n] = useTranslation("takenXML");
  return (
    <>
      <h4>{t("cabecera.frecuperada")}</h4>
      <div className="row text-left">
        <div className="col col-12">
          <span style={{ fontWeight: "bold" }}>{t("cabecera.emisorRFC")}:</span>
          <span>{objXml["objXml"]["Emisor"]["@attributes"]["Rfc"]}</span>
        </div>
        <div className="col col-12">
          <span style={{ fontWeight: "bold" }}>{t("cabecera.nombre")}: </span>
          <span>{objXml["objXml"]["Emisor"]["@attributes"]["Nombre"]}</span>
        </div>
        <div className="col col-12">
          <span style={{ fontWeight: "bold" }}>{t("cabecera.regimenFiscal")}: </span>
          <span>
            {objXml["objXml"]["Emisor"]["@attributes"]["RegimenFiscal"]}
          </span>
        </div>
        <div className="col col-12">
          <span style={{ fontWeight: "bold" }}>{t("cabecera.refrencias")}: </span>
          <span>{objXml.referencias}</span>
        </div>
      </div>
      <div className="row table-sm">
        <table className="table table-sm">
          <thead className="titulotabla-sm">
            <tr>
              <th>{t("tabla.cnt")}</th>
              <th>{t("tabla.clave")}</th>
              <th>{t("tabla.pu")}</th>
              <th>Total</th>
            </tr>
          </thead>
          <tbody>
            {objXml.objXml.Conceptos.map((item, i) => (
              <React.Fragment key={i}>
                <tr>
                  <td className="td-sm">
                    {item["concepto"]["@attributes"]["Cantidad"]}
                  </td>
                  <td className="td-sm">
                    {item["concepto"]["@attributes"]["ClaveProdServ"]}
                  </td>
                  <td className="td-sm">
                    <NumberFormat
                      displayType={"text"}
                      thousandSeparator={true}
                      prefix={"$"}
                      value={item["concepto"]["@attributes"]["ValorUnitario"]}
                    />
                  </td>
                  <td className="td-sm">
                    <NumberFormat
                      displayType={"text"}
                      thousandSeparator={true}
                      prefix={"$"}
                      value={item["concepto"]["@attributes"]["Importe"]}
                    />
                  </td>
                </tr>
                <tr>
                  <td className="th-sm">{t("footer.descripcion")}:</td>
                  <td colSpan="3" className="td-sm">
                    {item["concepto"]["@attributes"]["Descripcion"]}
                  </td>
                </tr>
              </React.Fragment>
            ))}
          </tbody>
        </table>
      </div>
      <div className="row">
        <div className="col col-7 text-left">
          <label htmlFor="re" style={{ fontWeight: "bold" }}>
          {t("footer.comentarios")}:
          </label>
          <textarea
            className="form-control"
            name="comentarios"
            value={objXml.comentarios === undefined ? null : objXml.comentarios}
            readOnly
          />
        </div>
        <div className="col col-5 text-right">
          <div className="col col-12">
            <span style={{ fontWeight: "bold" }}>Subtotal: </span>
            <span>
              <NumberFormat
                displayType={"text"}
                allowNegative={true}
                thousandSeparator={true}
                prefix={"$"}
                value={
                  objXml["objXml"]["Comprobante"]["@attributes"][
                    "TipoDeComprobante"
                  ] === "E"
                    ? objXml["objXml"]["Comprobante"]["@attributes"][
                        "SubTotal"
                      ] * -1
                    : objXml["objXml"]["Comprobante"]["@attributes"]["SubTotal"]
                }
              />
            </span>
          </div>
          <div className="col col-12">
            <span style={{ fontWeight: "bold" }}>IVA: </span>
            <span>
              <NumberFormat
                displayType={"text"}
                thousandSeparator={true}
                prefix={"$"}
                value={
                  objXml["objXml"]["impuestosTraslados"] !== undefined &&
                  !Array.isArray(objXml["objXml"]["impuestosTraslados"])
                    ? objXml["objXml"]["Comprobante"]["@attributes"][
                        "TipoDeComprobante"
                      ] === "E"
                      ? objXml["objXml"]["impuestosTraslados"]["@attributes"][
                          "TotalImpuestosTrasladados"
                        ] * -1
                      : objXml["objXml"]["impuestosTraslados"]["@attributes"][
                          "TotalImpuestosTrasladados"
                        ]
                    : 0
                }
              />
            </span>
          </div>
          <div className="col col-12">
            <span style={{ fontWeight: "bold" }}>RET: </span>
            <span>
              <NumberFormat
                displayType={"text"}
                thousandSeparator={true}
                prefix={"$"}
                value={
                  objXml["objXml"]["impuestosTraslados"] !== undefined &&
                  !Array.isArray(objXml["objXml"]["impuestosTraslados"])
                    ? objXml["objXml"]["Comprobante"]["@attributes"][
                        "TipoDeComprobante"
                      ] === "E"
                      ? objXml["objXml"]["impuestosTraslados"]["@attributes"][
                          "TotalImpuestosRetenidos"
                        ] * -1
                      : objXml["objXml"]["impuestosTraslados"]["@attributes"][
                          "TotalImpuestosRetenidos"
                        ]
                    : 0
                }
              />
            </span>
          </div>
          {/*Descuento Nomina*/}
          <div className="col col-12">
          <span style={{ fontWeight: "bold" }}>{t("footer.descuento")}: </span>
            <span>
              <NumberFormat
                displayType={"text"}
                thousandSeparator={true}
                prefix={"$"}
                value={
                  objXml["objXml"]["Comprobante"]["@attributes"][
                    "Descuento"
                  ]
                }
              />
            </span>
          </div>
          <div className="col col-12">
            <span style={{ fontWeight: "bold" }}>Total: </span>
            <span>
              <NumberFormat
                displayType={"text"}
                thousandSeparator={true}
                prefix={"$"}
                value={
                  objXml["objXml"]["Comprobante"]["@attributes"][
                    "TipoDeComprobante"
                  ] === "E"
                    ? objXml["objXml"]["Comprobante"]["@attributes"]["Total"] *
                      -1
                    : objXml["objXml"]["Comprobante"]["@attributes"]["Total"]
                }
              />
            </span>
          </div>
        </div>
      </div>
    </>
  );
};
export default TakenXML;
