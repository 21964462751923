import axios from "axios";
import { URL } from "../utils/globalConect";

export const delArchivo = (section, props, id) => {
  return (dispatch) => {
    axios
      .delete(`${URL}${section}/${id}`, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: props.session.token,
        },
      })
      .then(() => {
        dispatch({
          type: "DELETEARCHIVO",
          payload: id,
        });
      })
      .catch(() => {
        throw new Error("Error al eliminar");
      });
  };
};

export const addArchivo = (section, props, archivo) => {
  return async (dispatch) => {
     let fromFata = new FormData();
            fromFata.append("files[]", archivo.b64);
            fromFata.append("idFactura", archivo.idFactura);
    return axios
      .post(`${URL}${section}`, fromFata, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: props.session.token,
        }
      })
      .then(({ data }) => {
        dispatch({
          type: "ADDARCHIVO",
          payload: data.archivo,
        });
      })
      .catch(() => {
        throw new Error("Error al eliminar");
      });
  };
};
