import React from "react";
import { Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { logOut } from "../actions";

const auth = {
  isAuthenticated(exp, props) {
    if (props.auth.includes(props.session.user.role)) {
      exp = exp ? exp : 0;
      let cd = new Date();
      let ct = cd.getTime() / 1000;
      if (Number(exp) < Number(ct)) {
        props.logOut();
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  }
};

const PrivateRoute = ({ children, ...props }) => {
  const { session } = props;
  return (
    <Route
      {...props}
      render={({ location }) =>
        auth.isAuthenticated(session.exp, props) ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/",
              state: { from: location }
            }}
          />
        )
      }
    />
  );
};

const mapStateProps = state => {
  return {
    session: state.session
  };
};

const mapDispathToProps = {
  logOut
};

export default connect(mapStateProps, mapDispathToProps)(PrivateRoute);
