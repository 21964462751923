import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";

import PrivateRoute from "../utils/privateRoute";

import Layout from "../components/Layout";
import Login from "../pages/Login";

import NotFound from "../pages/NotFound";
import Landing from "../pages/Landing";
import FacturasProgramadas from "../pages/FacturasProgramadas";
import Usuarios from "../pages/Usuarios";
import FetchFacturas from "../pages/FetchFacturas";
import FetchRfcFacturas from "../pages/FetchRfcFacturas";
import FacturaView from "../pages/FacturaView";
import FiltroForm from "../components/FiltroForm";

const App = () => (
  <BrowserRouter>
    <Layout>
      <Switch>
        <Route exact path="/" component={Login} />
        <PrivateRoute path="/home" auth={["DEVELOPER", "ADMIN","OPERACIONES"]} >
        <Route component={Landing} />
        </PrivateRoute>
        <PrivateRoute path="/program" auth={["DEVELOPER", "ADMIN"]} >
        <Route component={FacturasProgramadas} />
        </PrivateRoute>
        <PrivateRoute path="/fdeetals" auth={["DEVELOPER", "ADMIN","OPERACIONES"]}>
          <Route component={FetchRfcFacturas} />
        </PrivateRoute>
        <PrivateRoute path="/usuarios" auth={["DEVELOPER", "ADMIN"]}>
          <Route component={Usuarios} />
        </PrivateRoute>
        <PrivateRoute path="/facturas" auth={["DEVELOPER", "USER"]}>
          <Route component={FetchFacturas} />
        </PrivateRoute>
        <PrivateRoute path="/factura/:id" auth={["DEVELOPER", "ADMIN","OPERACIONES","USER"]}>
          <Route component={FacturaView}/>
        </PrivateRoute>
        <Route path="/calis" component ={FiltroForm}/>
        <Route component={NotFound} />
      </Switch>
    </Layout>
  </BrowserRouter>
);

export default App;
