import React, { useState } from "react";
import axios from "axios";
import { AUTH, FURL } from "../utils/globalConect";
import NoData from "./NoData";
import ConceptosFactura from "./ConceptosFactura";

const FacturasCliente = (props) => {
  const [isLoading, setloadin] = useState(false);
  const [facturas, setFacturas] = useState([]);
  const [isToggle, setToggle] = useState(null);

  useState(() => {
    setloadin(true);
    axios
      .get(
        `${FURL}FacturaApi.json?RFMfind=SELECT%20%2A%20WHERE%20FILE_VALITON%20%3D%20%27${props.file}%27`,
        {
          auth: AUTH,
          headers: {
            "Access-Control-Allow-Headers":
              "Origin, X-Requested-With, Content-Type, Accept",
            "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE",
            "cache-control": "no-cache",
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
          },
        }
      )
      .then(({ data }) => {
        setFacturas(data.data);
        setloadin(false);
      })
      .catch(() => {
        setloadin(false);
      });
  });

  const handelTogglin = (index) =>{
    if (isToggle === index) {
      setToggle(null)
    }else{
      setToggle(index)
    }
  }

  const handleExit = () => {
    props.handelHide();
  };

  return (
    <div className="modalprincipal">
      <div className="submodal">
        <div className="submodalTitle">
          <span>FACTURAS RELACIONAS</span>
        </div>
      </div>
      <div className="modalcontenido">
        <div className="row">
          {isLoading === true ? (
            <div className="principal">
              <div className="spinner-grow" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            </div>
          ) : (
            <div className="tabla-sm-cal col-12">
              {facturas && facturas.length > 0 ? (
                <table className="table table-sm">
                  <thead className="titulotabla-sm">
                    <tr>
                      <th>Folio</th>
                      <th>Cliente</th>
                      <th>F-Factura</th>
                      <th>Status</th>
                      <th>Pago</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {facturas.map((item, i) => (
                      <React.Fragment key={i}>
                        <tr>
                          <td className="td-sm">
                            {item["FOLIO"] === null ||
                            item["FOLIO"] === undefined
                              ? "Proforma"
                              : item["FOLIO"]}
                          </td>
                          <td className="td-sm">{item["CLIENTE"]}</td>
                          <td className="td-sm">
                            {item["FECHA FACT"] === null ||
                            item["FECHA FACT"] === undefined
                              ? "Pendiente"
                              : item["FECHA FACT"]}
                          </td>
                          <td className="td-sm">{item["STATUS"]}</td>
                          <td className="td-sm">{item["STATUS_PAGO"]}</td>
                          <td>
                            <button
                            style={{border:0,padding:0}}
                              className="btn btn-link btn-sm"
                              onClick={() => handelTogglin(i)}
                            >
                              <i className="far fa-eye"></i>
                            </button>
                          </td>
                        </tr>
                        {isToggle !== null && isToggle === i && (
                          <tr>
                            <td colSpan="5">
                              <div className="col col-12">
                                <ConceptosFactura folio={item["FOLIO"]}/>
                              </div>
                            </td>
                          </tr>
                        )}
                      </React.Fragment>
                    ))}
                  </tbody>
                </table>
              ) : (
                <NoData />
              )}
            </div>
          )}
        </div>
        <hr />
        <div className="row botonmodal">
          <div className="col-1">
            <button className="btn btn-danger" onClick={handleExit}>
              Salir
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FacturasCliente;
