import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import NumberFormat from "react-number-format";

import NoData from "../components/NoData";

import "../assets/styles/components/TablePage.css";
import "../assets/styles/Filtrado.css";

const FetchRfcFacturas = (props) => {
  const [searchForm, setInput] = useState("");
  const [filtrados, setFilter] = useState(null);

  useEffect(() => {
    setFilter(props.facturas);
  }, [props.facturas]);

  const handleFinderChange = (event) => {
    setInput(event.target.value.toLowerCase());
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (searchForm) {
      setFilter(
        props.facturas.filter(
          (i) =>
            i.razonSocial.toLowerCase() === searchForm ||
            i.RFCUser.toLowerCase() === searchForm
        )
      );
    } else {
      setFilter(props.facturas);
    }
  };

  return (
    <div className="principal">
      <div className="text-center filter">
        <h1>Facturas</h1>
      </div>
      <div className="d-flex justify-content-center">
        <form className="d-flex" onSubmit={handleSubmit}>
          <input
            id="date"
            type="text"
            value={searchForm}
            onChange={handleFinderChange}
            className="form-control"
            placeholder="Folio/Fecha"
            aria-label="Recipient's username"
          />
          <button className="btn btn-info goFilter" type="submit">
            <i className="fas fa-search"></i>
          </button>
        </form>
      </div>
      <div className="tablagen">
        {filtrados && filtrados.length >= 0 ? (
          <table style={{ width: "100%" }}>
            <thead>
              <tr className="titulotabla">
                <th>Folio</th>
                <th>F-Factura</th>
                <th>F-Timbrado</th>
                <th>Moneda</th>
                <th>SubTotal</th>
                <th>Iva</th>
                <th>Retencion</th>
                <th>Total</th>
                <th>Tipo</th>
                <th>Rechazo Admin</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {filtrados.map((item) => (
                <tr className="contenido" key={item.id}>
                  <td>{item.folio}</td>
                  <td>{item.fechaFactura}</td>
                  <td>{item.fechaTimbrado}</td>
                  <td>{item.moneda}</td>
                  <td>
                    <NumberFormat
                      displayType={"text"}
                      thousandSeparator={true}
                      prefix={"$"}
                      value={item.subTotal}
                    />
                  </td>
                  <td>
                    <NumberFormat
                      displayType={"text"}
                      thousandSeparator={true}
                      prefix={"$"}
                      value={item.iva}
                    />
                  </td>
                  <td>
                    <NumberFormat
                      displayType={"text"}
                      thousandSeparator={true}
                      prefix={"$"}
                      value={item.retencion}
                    />
                  </td>
                  <td>
                    <NumberFormat
                      displayType={"text"}
                      thousandSeparator={true}
                      prefix={"$"}
                      value={item.total}
                    />
                  </td>
                  <td>{item.serie}</td>
                  <td>{item.motivosRechazoAdmin}</td>
                  <td>
                    <Link className="btn btn-link" to={`/factura/${item.id}`}>
                      <i className="far fa-eye"></i>
                    </Link>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <NoData />
        )}
      </div>
    </div>
  );
};

const mapStateToprops = (state) => {
  return {
    session: state.session,
    facturas: state.facturas,
  };
};

const mapDispathToProps = {};

export default connect(mapStateToprops, mapDispathToProps)(FetchRfcFacturas);
