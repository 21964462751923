import React, { useState, useEffect } from "react";
import { fetchFacturasGrup, viewRfcFacturas } from "../actions/facturas";
import { connect } from "react-redux";
import NoData from "../components/NoData";
import "../assets/styles/components/TablePage.css";
import "../assets/styles/Filtrado.css";

const Landing = (props) => {
  useState(() => props.fetchFacturasGrup("facgru/PENDIENTE", props));
  const [searchForm, setInput] = useState("");
  const [filtrados, setFilter] = useState(null);
  const [typeView] = useState("PENDIENTE");

  useEffect(() => {
    setFilter(props.facturaGrup);
  }, [props.facturaGrup]);

  const handleFinderChange = (event) => {
    setInput(event.target.value.toLowerCase());
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (searchForm) {
      setFilter(
        props.facturaGrup.filter(
          (i) =>
            i.razonSocial.toLowerCase() === searchForm ||
            i.RFCUser.toLowerCase() === searchForm
        )
      );
    } else {
      setFilter(props.facturaGrup);
    }
  };

  const handleGoto = (rfc) => {
    props.viewRfcFacturas(rfc, typeView, "fabrfc", props);
  };

  return (
    <div className="principal">
      <div className="text-center filter">
        <h1>Facturas Proveedores</h1>
        <div className="d-flex justify-content-center">
          <form className="d-flex" onSubmit={handleSubmit}>
            <input
              id="date"
              type="text"
              value={searchForm}
              onChange={handleFinderChange}
              className="form-control"
              placeholder="RFC/Razon Social"
              aria-label="Recipient's username"
            />
            <button className="btn btn-info goFilter" type="submit">
              <i className="fas fa-search"></i>
            </button>
          </form>
        </div>
      </div>
      <div className="tablagen">
        {filtrados ? (
          filtrados.map((item) => (
            <div className="list-group" key={item.idUser}>
              <button
                type="button"
                onClick={() => handleGoto(item.idUser)}
                className="list-group-item list-group-item-action  d-flex justify-content-between align-items-center"
              >
                <h5>{item.razonSocial}</h5>
                <h4>
                  <span
                    className="badge badge-primary badge-pill"
                    style={{ backgroundColor: "#8f57fd" }}
                  >
                    {item.pendiente}
                  </span>
                </h4>
              </button>
            </div>
          ))
        ) : (
          <NoData />
        )}
      </div>
    </div>
  );
};

const mapStateToprops = (state) => {
  return {
    session: state.session,
    facturaGrup: state.facturaGrup,
  };
};

const mapDispathToProps = {
  viewRfcFacturas,
  fetchFacturasGrup,
};
export default connect(mapStateToprops, mapDispathToProps)(Landing);
