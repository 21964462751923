import React, { useState, useEffect, Fragment } from "react";
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import { connect } from "react-redux";
import { fetchProviders } from "../actions/usuarios";
import {getTipoCambio} from "../actions/facturas";
import NumberFormat from "react-number-format";

const PagoDialog = (props) => {
  const [form, setValues] = useState({
    fechaPago: new Date().toLocaleDateString("fr-CA"),
    idCuenta: "",
    cuenta: "",
    alias: "",
    moneda: "",
    tipoCambio:0
  });
  const [options, setOptions] = useState([]);
  const [isSearching, setSearch] = useState(false);
  const [isValid, setValid] = useState(false);

  const nowTipoCambio = (fechas) =>{
    props.getTipoCambio(
      `TCApi.json?RFMfind=SELECT%20%2A%20WHERE%20FechaWeb%20%3D%20%27${fechas}%27`
    ).then((data)=>{
      setValues({
        ...form,
        tipoCambio: Number(data.data[0]["VALOR_USD_comercial"])
      })
    }).catch(()=>{console.log("mames")})
  }

  useState(()=>{
    console.log(props);
    if (props.factura.moneda !== "MXN") {
      nowTipoCambio(form.fechaPago);
    }else{
      setValues({
        ...form,
        tipoCambio:1
      })
    }
    
  })

  useEffect(() => {
    if (form.fechaPago !== null && form.idCuenta.length > 0 && form.tipoCambio > 0 ) {
      setValid(true);
    } else {
      setValid(false);
    }
  }, [form, isValid]);

  const myRef = React.createRef();

  const handleTagblur = () => {
    let datito = myRef.current;
    nowTipoCambio(datito.value)
  }


  const handleChange = (event) => {
    setValues({
      ...form,
      fechaPago: event.target.value
    });
  };

  const handleChangeTC = (event) => {
    setValues({
      ...form,
      tipoCambio: event.value
    });
  };

  const handleFindBanco = (query) => {
    props
      .fetchProviders(
        `MisBancosApi.json?RFMfind=SELECT%20%2A%20WHERE%20ALIAS%20LIKE%20%27${encodeURIComponent(
          query
        )}%2A%27`
      )
      .then((data) => {
        setOptions(data.data);
      });
  };

  const handelSelec = ([selectedProvider]) => {
    setSearch(true);
    if (selectedProvider !== undefined) {
      setValues({
        ...form,
        idCuenta: selectedProvider["__ID_MI BANCO"],
        alias: selectedProvider["ALIAS"],
        cuenta: selectedProvider["CTA"],
        moneda: selectedProvider["MONEDA"],
      });
      setSearch(false);
    }
  };

  const pagar = () => {
    props.pagar(form);
    props.handelHide();
  };

  const cbHide = () => {
    props.handelHide();
  };

  return (
    <div className="modalprincipal">
      <div className="submodal">
        <div className="submodalTitle">
          <span>INFORMACION DE PAGO</span>
        </div>
      </div>
      <div className="modalcontenido">
        <div className="row">
          <div className="col col-4" onBlur={handleTagblur}>
            <label htmlFor="re">Fecha Pago:</label>
            <input
            ref={myRef}
              type="date"
              name="fechaPago"
              value={form.fechaPago}
              className="form-control"
              onChange={handleChange}
            />
          </div>
          <div className="col col-5">
            <label htmlFor="re">Proveedor:</label>
            <AsyncTypeahead
              isLoading={isSearching}
              id="async-providers"
              minLength={3}
              onSearch={handleFindBanco}
              options={options}
              placeholder={form.alias || "Buscar Banco"}
              labelKey={"ALIAS"}
              onChange={(selectedData) => handelSelec(selectedData)}
              renderMenuItemChildren={(options) => (
                <Fragment>
                  <span>
                    {options["ALIAS"].trim()}-{options["MONEDA"]}
                  </span>
                </Fragment>
              )}
            />
          </div>
          <div className="col col-3">
            <label htmlFor="re">Tipo Cambio:</label>
            <NumberFormat
              className="form-control"
              displayType="input"
              thousandSeparator={true}
              allowNegative={true}
              value={form.tipoCambio}
              onValueChange={handleChangeTC}
              disabled = {props.factura.moneda === "MXN"}
              prefix={"$"}
            />
          </div>
        </div>
        <hr />
        <div className="row botonmodal">
          <div className="col col-6">
            <button
              className=" btn btn-link aceptar"
              type="button"
              onClick={pagar}
              disabled={!isValid}
            >
              Pagar
            </button>
          </div>
          <div className="col col-6">
            <button
              className=" btn btn-link cancelar"
              type="button"
              onClick={cbHide}
            >
              Cancelar
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToprops = (state) => {
  return {
    token: state.session.token,
    providers: state.providers,
    factura: state.factura.Factura,
  };
};
const mapDispathToProps = {
  fetchProviders,
  getTipoCambio
};

export default connect(mapStateToprops, mapDispathToProps)(PagoDialog);
