import React, { useState } from "react";
import axios from "axios";
import { AUTH, FURL } from "../utils/globalConect";

const ConceptosFactura = (props) => {
  const [conceptos, setConceptos] = useState([]);
  const [isLoading, setloadin] = useState(false);

  useState(() => {
    setloadin(true);
    axios
      .get(
        `${FURL}ConceptoFacturaApi.json?RFMfind=SELECT%20%2A%20WHERE%20FOLIO%20%3D%20%27${props.folio}%27`,
        {
          auth: AUTH,
          headers: {
            "Access-Control-Allow-Headers":
              "Origin, X-Requested-With, Content-Type, Accept",
            "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE",
            "cache-control": "no-cache",
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
          },
        }
      )
      .then(({ data }) => {
        setConceptos(data.data);
        setloadin(false); 
      })
      .catch(() => {});
  });

  return (
    <>
      {isLoading === true ? (
          <div className="spinner-grow" role="status">
            <span className="sr-only">Loading...</span>
          </div>
      ) : (
         <table className="table table-sm">
             <thead className="titulotabla-sm">
                 <tr>
                     <th>Concepto</th>
                     <th>Subtotal</th>
                     <th>Iva</th>
                     <th>Ret</th>
                     <th>Total</th>
                     <th>Divisa</th>
                 </tr>
             </thead>
             <tbody>
                 {conceptos.map((item, i)=>(
                     <tr key={i}>
                         <td className="td-sm">{item["CONCEPTO MAS COMENTARIO"]}</td>
                         <td className="td-sm">{item["SUBTOTAL"]}</td>
                         <td className="td-sm">{item["IVA"]}</td>
                         <td className="td-sm">{item["RETENCION"]}</td>
                         <td className="td-sm">{item["IMPORTE"]}</td>
                         <td className="td-sm">{item["CODIGO_DIVISA"]}</td>
                     </tr>
                 ))}
             </tbody>
         </table>
      )}
    </>
  );
};
export default ConceptosFactura;
