import React, { useState } from "react";
import { connect } from "react-redux";
import NumberFormat from "react-number-format";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { findEmbarque } from "../actions/facturas";
import { applyConcepto } from "../actions/profit";

const ProfitViewer = (props) => {
  //Campos y variables
  const [BRef, SetRef] = useState("");
  const [Conceptos, SetConceptos] = useState();
  const MySwal = withReactContent(
    Swal.mixin({
      toast: true,
      position: "top-end",
      showConfirmButton: false,
      timer: 2000,
      timerProgressBar: true,
      onOpen: (toast) => {
        toast.addEventListener("mouseenter", Swal.stopTimer);
        toast.addEventListener("mouseleave", Swal.resumeTimer);
      },
    })
  );

  const handleChange = (e) => {
    SetRef(e.target.value.toUpperCase());
  };

  const handleFind = (event) => {
    event.preventDefault();
    let type = BRef.substr(0, BRef.indexOf("-"));
    let callUrl = null;
    switch (type) {
      case "MAR":
        callUrl =
          `ApiProfitMaritimo.json?RFMfind=SELECT%20%2A%20WHERE%20TIPO_COSTO_VENTA%20%3D%20COSTO%20AND%20DIVISA%20%3D%20${props.factura.Factura.moneda}%20AND%20FILE_VALITON%20%3D%20`;
        break;
      case "TER":
        callUrl =
          `ApiProfit.json?RFMfind=SELECT%20%2A%20WHERE%20COSTO_VENTA%20%3D%20COSTO%20AND%20DIVISA%20%3D%20${props.factura.Factura.moneda}%20AND%20ID_FILE_VALITON%20%3D%20`;
        break;
      case "AER":
        callUrl =
          `ApiProfit.json?RFMfind=SELECT%20%2A%20WHERE%20COSTO_VENTA%20%3D%20COSTO%20AND%20DIVISA%20%3D%20${props.factura.Factura.moneda}%20AND%20ID_FILE_VALITON%20%3D%20`;
        break;
      default:
        SetConceptos();
        MySwal.fire({
          icon: "error",
          title: "Formato de referencia no valido",
        });
        break;
    }
    if (callUrl !== null) {
      props.findEmbarque(BRef, callUrl, type).then((data) => {
      if (data.info !== undefined) {
        SetConceptos();
        MySwal.fire({
          icon: "error",
          title: data.info["X-RESTfm-FM-Reason"],
        });
      } else {
        SetConceptos(data);
      }
    }).catch(()=>{
      MySwal.fire({
          icon: "error",
          title: "VPN Valiton no Responde",
        });
    });
    }
    
  };

  const addConepto = (item, meta) => {
    delete meta["href"];
    var ress = [];
    item.idFacturaWeb = props.factura.Factura.id;
    item.facturaProveedor = props.factura.Factura.folio;
    item.facturaFactura = props.factura.Factura.fechaFactura;
    item.seleccionado = 1;
    item.tref = item.referencia.split("-")[0];
    ress = ress.concat(meta, item);
    props.applyConcepto(ress);
  };

  return (
    <div>
      <h4>Buscador de Conceptos</h4>
      <div className="text-center">
        <div className="d-flex justify-content-center">
          <form
            className="form-inline"
            onSubmit={handleFind}
            style={{ marginTop: "10px" }}
          >
            <input
              value={BRef}
              onChange={handleChange}
              className="form-control"
              type="text"
              placeholder="Referencia"
              required
            />
            <button className="btn btn-info goFilter" type="submit">
              <i className="fas fa-search"></i>
            </button>
          </form>
        </div>
      </div>
      <br />
      <div className="tabla-sm-cal">
        <table className="table table-sm">
          <thead className="titulotabla-sm">
            <tr>
              <th>Cnt</th>
              <th>Concepto</th>
              <th>Divisa</th>
              <th>Monto</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {Conceptos !== undefined &&
              Conceptos.emb.map((item, i) => (
                <React.Fragment key={i}>
                  <tr>
                    <td className="td-sm text-center">{item.cantidad}</td>
                    <td className="td-sm">{item.concepto}</td>
                    <td className="td-sm text-center">{item.divisa}</td>
                    <td className="td-sm text-right">
                      <NumberFormat
                        value={item.monto}
                        displayType={"text"}
                        thousandSeparator={true}
                        prefix={"$"}
                      />
                    </td>
                    <td className="td-sm text-center">
                      <button
                        onClick={() => addConepto(item, Conceptos.meta[i])}
                        className="btn btn-link btn-sm"
                      >
                        <i className="fas fa-plus"></i>
                      </button>
                    </td>
                  </tr>
                  {item.contenedores !== undefined && (
                    <tr>
                      <td className="th-sm">Info:</td>
                      <td colSpan="3" className="td-sm">
                        {item.contenedores}
                      </td>
                    </tr>
                  )}
                </React.Fragment>
              ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

const mapStateToprops = (state) => {
  return {
    session: state.session,
  };
};

const mapDispathToProps = {
  findEmbarque,
  applyConcepto,
};

export default connect(mapStateToprops, mapDispathToProps)(ProfitViewer);
