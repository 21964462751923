import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import NumberFormat from "react-number-format";
import Swal from "sweetalert2";
import { Toggle, Modal } from "../utils/modalUtil.jsx";

import withReactContent from "sweetalert2-react-content";
import {
  sendStatusFactura,
  getFactura,
  clearFactura,
  acceptFactura,
  rejectFactura,
  applyPago,
  getTerprofit,
  programarFactura,
  getTipoCambio,
} from "../actions/facturas";
import { frontToBack,clearFrontToBack } from "./../utils/ExchangeFormat";

import ProfitViewer from "../components/ProfitViewer";
import TakenXML from "./../components/TakenXML";
import TakenNoXML from "./../components/TakenNoXML";
import DocumentList from "./../components/DocumentList";
import ProfitSelected from "../components/ProfitSelected";
import PagoDialog from "../components/PagoDialog";

import "@pathofdev/react-tag-input/build/index.css";
import "../assets/styles/components/FormModal.css";

const FacturaView = (props) => {
  const id = props.match.params.id;
  const history = useHistory();
  const MySwal = withReactContent(Swal);
  const MyTcSwal = withReactContent(Swal);
  const MyToast = withReactContent(
    Swal.mixin({
      toast: true,
      position: "top-end",
      showConfirmButton: false,
      timer: 2000,
      timerProgressBar: true,
      onOpen: (toast) => {
        toast.addEventListener("mouseenter", Swal.stopTimer);
        toast.addEventListener("mouseleave", Swal.resumeTimer);
      },
    })
  );
  useEffect(() => {
    // a condition may be added in case it shouldn't be executed every time
    if (props.factura.Factura !== undefined) {
      let typeRef = ["MAR", "TER", "AER"];
      let arr = props.factura.Factura.referencia.split("-");
      SetIsFMref(typeRef.includes(arr[0]));
    }
  }, [props.factura.Factura]);
  const [Loading, SetLoading] = useState(true);
  const [isFMref, SetIsFMref] = useState(false);

  useState(() => {
    props.getFactura(id, "factura", props).then(() => {
      SetLoading(false);
    });
    props.getTerprofit(
      `ApiProfit.json?RFMfind=SELECT%20%2A%20WHERE%20IDFACTURAWEB%20%3D%20${id}%20AND%20SELECCIONADO%20%3D%201&RFMmax=0`,
      "TER"
    );
    props.getTerprofit(
      `ApiProfitMaritimo.json?RFMfind=SELECT%20%2A%20WHERE%20IDFACTURAWEB%20%3D%20${id}%20AND%20SELECCIONADO%20%3D%201&RFMmax=0`,
      "MAR"
    );
    props.clearFactura();
  });

  const rechasaFactura = () => {
    MySwal.fire({
      title: "Enviar Rechazo",
      icon: "question",
      input: "textarea",
      inputPlaceholder: "Motivo",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      cancelButtonText: "Cancelar",
      confirmButtonText: "Enviar",
      inputValidator: (value) => {
        if (!value) {
          return "se necesita un mensaje";
        }
      },
    }).then((result) => {
      if (result.value) {
        props.rejectFactura(
          result.value,
          props.factura.Factura.id,
          "reject",
          props
        );
        props.clearFactura();
        cliearLinkFilemaker();
        salirLista();
      }
    });
  };

  const linkFilemaker = () => {
    if (isFMref) {
      let npayload = frontToBack(props.profit);
      if (npayload.ter !== undefined && npayload.ter.data.length > 0) {
        props.applyPago(npayload.ter, "ApiProfit.json");
      }
      if (npayload.mar !== undefined && npayload.mar.data.length > 0) {
        props.applyPago(npayload.mar, "ApiProfitMaritimo.json");
      }
    }
  };

  const cliearLinkFilemaker = () => {
    if (isFMref) {
      let npayload = clearFrontToBack(props.profit);
      if (npayload.ter !== undefined && npayload.ter.data.length > 0) {
        props.applyPago(npayload.ter, "ApiProfit.json");
      }
      if (npayload.mar !== undefined && npayload.mar.data.length > 0) {
        props.applyPago(npayload.mar, "ApiProfitMaritimo.json");
      }
    }
  };

  const resibirFactura = () => {
    if (!isFMref) {
      let tc = props.factura.Factura.moneda === "MXN"? 1: 0;
      MyTcSwal.fire({
        title: "Tipo de cambio para esta factura",
        icon: "question",
        input: "number",
        inputPlaceholder: "Tipo de cambio",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "Cancelar",
        confirmButtonText: "Enviar",
        inputValidator: (value) => {
          if (!value) {
            return "se necesita un Monto";
          }
          if (value < 0) {
            return "monto no valido";
          }
        },
      }).then((result) => {
        if (result.value) {
          tc = result.value;
          let objEnvio = {
            tc: tc,
            ref: props.factura.Factura.referencia,
          };
          props.acceptFactura(objEnvio, props.factura.Factura.id, "acept", props);
          props.clearFactura();
          salirLista();
        }
      });
      return;
    }
    props
      .getTipoCambio(
        `TCApi.json?RFMfind=SELECT%20%2A%20WHERE%20FechaWeb%20%3D%20%27${props.factura.Factura.fechaFactura}%27`
      )
      .then((data) => {
        let tc =
          props.factura.Factura.moneda === "MXN"
            ? 1
            : data.data[0]["VALOR_USD_comercial"];
        let ref = isFMref? props.profit.data.map((x) => x.referencia).join(";"):props.factura.Factura.referencia;
        let objEnvio = {
          tc: tc,
          ref: ref,
        };
        props.acceptFactura(objEnvio, props.factura.Factura.id, "acept", props);
        linkFilemaker();
        props.clearFactura();
        salirLista();
      })
      .catch(() => {
        MyToast.fire({
          icon: "error",
          title: "No se encuentra el tipo de cambio",
        });
      });
  };

  const pagarFactura = (info) => {
    let nprofit = props.profit;
    nprofit.data.forEach((item) => {
      item.pagado = 1;
      item.fechaPagado = info.fechaPago;
    });
    //Escribe en la base de datos
    props.acceptFactura(info, props.factura.Factura.id, "pays", props);
    //Enviando a pagar
    linkFilemaker();
    props.clearFactura();
    salirLista();
  };

  const salirLista = () => {
    props.clearFactura();
    switch (props.session.user.role) {
      case "USER":
        history.push("/facturas");
        break;
      case "ADMIN":
        history.push("/program");
        break;
      case "OPERACIONES":
        history.push("/fdeetals");
        break;
      default:
        history.push("/program");
        break;
    }
  };

  const programar = () => {
    props.programarFactura("prog", id, props).then(() => {
      props.clearFactura();
    });
    salirLista();
  };

  const sumaImportes = (data = [], tipo) => {
    if (tipo === "IVA") {
      let sum = data.reduce((a, b) => {
        return a + b.iva;
      }, 0);
      return sum;
    } else {
      let sum = data.reduce((a, b) => {
        return a + b.ret;
      }, 0);
      return sum;
    }
  };

  const botonoera = (role) => {
    switch (role) {
      case "DEVELOPER":
        return (
          <>
            {props.factura.Factura.estatus === "RECIBIDO" && (
              <button className="btn btn-success" onClick={programar}>
                Programar
              </button>
            )}
            {props.factura.Factura.estatus === "PROGRAMADO" && (
              <Toggle
                toggle={(show) => (
                  <button className="btn btn-success" onClick={show}>
                    Pagar
                  </button>
                )}
                content={(hide) => (
                  <Modal>
                    <PagoDialog
                      handelHide={hide}
                      pagar={(args) => pagarFactura(args)}
                    />
                  </Modal>
                )}
              />
            )}
            {props.factura.Factura.estatus === "PENDIENTE" && (
              <button className="btn btn-success" onClick={resibirFactura}>
                Aplicar
              </button>
            )}
          </>
        );
      case "ADMIN":
        return (
          <>
            {props.factura.Factura.estatus === "RECIBIDO" && (
              <button className="btn btn-success" onClick={programar}>
                Programar
              </button>
            )}
            {props.factura.Factura.estatus === "PROGRAMADO" && (
              <Toggle
                toggle={(show) => (
                  <button className="btn btn-success" onClick={show}>
                    Pagar
                  </button>
                )}
                content={(hide) => (
                  <Modal>
                    <PagoDialog
                      handelHide={hide}
                      pagar={(args) => pagarFactura(args)}
                    />
                  </Modal>
                )}
              />
            )}
            {props.factura.Factura.estatus === "PENDIENTE" && (
              <button
                className="btn btn-success"
                onClick={resibirFactura}
                disabled={
                  isFMref &&
                  Number(props.profit.total) !==
                  Number(props.factura.Factura.total)
                    ? true
                    : false
                }
              >
                Aplicar
              </button>
            )}
          </>
        );
      case "OPERACIONES":
        return (
          <button
            className="btn btn-success"
            onClick={resibirFactura}
            disabled={
              isFMref &&
              Number(props.profit.total) !== 
              Number(props.factura.Factura.total)
                ? true
                : false
            }
          >
            Aplicar
          </button>
        );
      default:
        return null;
    }
  };

  return (
    <>
      {Loading === true ? (
        <div className="principal">
          <div className="spinner-grow" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      ) : (
        <div className="principal">
          <div className="row">
            <div
              className={
                props.factura.Factura.tipoFactura === "FILE"
                  ? "col col-6"
                  : "col col-12"
              }
            >
              {props.factura.DocXML !== undefined &&
              props.factura.DocXML !== null ? (
                <>
                  <TakenXML
                    objXml={props.factura.DocXML}
                    comentarios={props.factura.Factura.comentarios}
                    referencias={props.factura.Factura.referencia}
                  />
                </>
              ) : null}
              {props.factura.DocXML === null ? (
                <TakenNoXML props={props.factura.Factura} />
              ) : null}
              {props.factura.Factura.archivos !== undefined &&
              props.factura.Factura.archivos !== null ? (
                <DocumentList
                  props={props}
                  documentos={props.factura.Factura.archivos}
                  idFactura={id}
                  idFProveedor={props.factura.Factura.idFProveedor}
                />
              ) : null}
            </div>
            {props.factura.Factura.tipoFactura === "FILE" && (
              <div className="col col-6">
                {props.factura.Factura.estatus !== "RECHAZADO" ? (
                  <>
                    <ProfitViewer factura={props.factura} />
                    <ProfitSelected />
                  </>
                ) : (
                  <>
                    <h4>Motivos de Rechazo</h4>
                    <textarea
                      className="form-control"
                      name="comentarios"
                      value={
                        props.factura.Factura.motivosRechazo === undefined
                          ? null
                          : props.factura.Factura.motivosRechazo
                      }
                      readOnly
                    />
                  </>
                )}
              </div>
            )}
          </div>
          <div className=" row">
            <div className="col col-10 col-md-10 col-lg-10 col-xl-10"></div>
            {props.factura.Factura.tipoFactura === "FILE" && (
              <div className="col col-2 col-md-2 col-lg-2 col-xl-2">
                <div className="row d-flex justify-content-end">
                  <span style={{ fontWeight: "bold" }}>Subtotal: </span>
                  <span>
                    <NumberFormat
                      displayType="text"
                      allowNegative={true}
                      thousandSeparator={true}
                      prefix={"$"}
                      value={
                        props.profit.subTotal !== undefined &&
                        props.profit.subTotal !== null
                          ? props.profit.subTotal
                          : 0
                      }
                    />
                  </span>
                </div>
                <div className="row d-flex justify-content-end">
                  <span style={{ fontWeight: "bold" }}>Iva: </span>
                  <span>
                    <NumberFormat
                      displayType="text"
                      allowNegative={true}
                      thousandSeparator={true}
                      prefix={"$"}
                      value={
                        props.profit.total !== null
                          ? sumaImportes(props.profit.data, "IVA")
                          : 0
                      }
                    />
                  </span>
                </div>
                <div className="row d-flex justify-content-end">
                  <span style={{ fontWeight: "bold" }}>Retencion: </span>
                  <span>
                    <NumberFormat
                      displayType="text"
                      allowNegative={true}
                      thousandSeparator={true}
                      prefix={"$"}
                      value={
                        props.profit.total !== null
                          ? sumaImportes(props.profit.data, "RET")
                          : 0
                      }
                    />
                  </span>
                </div>
                <div className="row d-flex justify-content-end">
                  <span style={{ fontWeight: "bold" }}>Total: </span>
                  <span>
                    <NumberFormat
                      displayType="text"
                      thousandSeparator={true}
                      allowNegative={true}
                      prefix={"$"}
                      value={
                        props.profit.total !== undefined &&
                        props.profit.total !== null
                          ? props.profit.total
                          : 0
                      }
                    />
                  </span>
                </div>
              </div>
            )}
            <div className="col col-12">
              {botonoera(props.session.user.role, "RET")}
              {props.factura.Factura.estatus !== "PAGADO" &&
                props.factura.Factura.estatus !== "RECHAZADO" && (
                  <button
                    className="btn btn-secondary"
                    onClick={rechasaFactura}
                  >
                    Rechazar
                  </button>
                )}
              <button
                className="btn btn-primary"
                onClick={linkFilemaker}
                disabled={
                  Number(props.profit.total) !==
                  Number(props.factura.Factura.total)
                    ? true
                    : false
                }
              >
                Ligar a Filemker
              </button>
              <button className="btn btn-danger" onClick={salirLista}>
                Salir
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

const mapStateToprops = (state) => {
  return {
    factura: state.factura,
    session: state.session,
    profit: state.profit,
  };
};

const mapDispathToProps = {
  sendStatusFactura,
  getFactura,
  clearFactura,
  acceptFactura,
  rejectFactura,
  applyPago,
  getTerprofit,
  programarFactura,
  getTipoCambio,
};

export default connect(mapStateToprops, mapDispathToProps)(FacturaView);
