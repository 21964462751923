import React from "react";
import NumberFormat from "react-number-format";
import { useTranslation } from "react-i18next";
import "../assets/styles/components/TablePageSM.css";

const TakenNoXML = (props) => {
  const [t, i18n] = useTranslation("takenXML");
  return (
    <>
      <h4>{t("cabecera.frecuperada")}</h4>
      <div className="row text-left">
        <div className="col col-12">
          <span style={{ fontWeight: "bold" }}>{t("cabecera.emisorRFC")}: </span>
          <span>{props.props.RFCUser}</span>
        </div>
        <div className="col col-12">
          <span style={{ fontWeight: "bold" }}>{t("cabecera.nombre")}: </span>
          <span>{props.props.user.razonSocial}</span>
        </div>
        <div className="col col-12">
          <span style={{ fontWeight: "bold" }}>{t("cabecera.regimenFiscal")}: </span>
          <span>EXTRANJERO</span>
        </div>
        <div className="col col-12">
          <span style={{ fontWeight: "bold" }}>{t("cabecera.refrencias")}: </span>
          <span>{props.props.referencia}</span>
        </div>
      </div>
      <div className="row table-sm">
        <table className="table table-sm">
          <thead className="titulotabla-sm">
            <tr>
            <th>{t("tabla.cnt")}</th>
              <th>{t("tabla.clave")}</th>
              <th>{t("tabla.pu")}</th>
              <th>Total</th>
            </tr>
          </thead>
          <tbody>
            <tr>
            <td className="td-sm">1</td>
            <td className="td-sm">N/A</td>
            <td className="td-sm">
              <NumberFormat
                displayType={"text"}
                thousandSeparator={true}
                prefix={"$"}
                value={props.props.total}
              />
            </td>
            <td className="td-sm">
              <NumberFormat
                displayType={"text"}
                thousandSeparator={true}
                prefix={"$"}
                value={props.props.total}
              />
            </td>
            </tr>
            <tr>
              <td className="th-sm">{t("footer.descripcion")}:</td>
              <td colSpan="3" className="td-sm">
                Servicio de provedor externo
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="row">
        <div className="col col-7 text-left">
          <label htmlFor="re" style={{ fontWeight: "bold" }}>
          {t("footer.comentarios")}:
          </label>
          <textarea
            className="form-control"
            name="comentarios"
            value={
              props.props.comentarios === undefined
                ? null
                : props.props.comentarios
            }
            readOnly
          />
        </div>
        <div className="col col-5 text-right">
          <div className="col col-12">
            <span style={{ fontWeight: "bold" }}>Subtotal: </span>
            <span>
              <NumberFormat
                displayType={"text"}
                thousandSeparator={true}
                prefix={"$"}
                value={props.props.total}
              />
            </span>
          </div>
          <div className="col col-12">
            <span style={{ fontWeight: "bold" }}>IVA: </span>
            <span>N/A</span>
          </div>
          <div className="col col-12">
            <span style={{ fontWeight: "bold" }}>RET: </span>
            <span>N/A</span>
          </div>
          <div className="col col-12">
            <span style={{ fontWeight: "bold" }}>Total: </span>
            <span>
              <NumberFormat
                displayType={"text"}
                thousandSeparator={true}
                prefix={"$"}
                value={props.props.total}
              />
            </span>
          </div>
        </div>
      </div>
    </>
  );
};

export default TakenNoXML;
