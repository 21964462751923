import React, { useCallback } from "react";
import { useDropzone } from "react-dropzone";

import "../assets/styles/utils/dragAndDrop.css";

const customFileName = (length) => {
  var result = "";
  var characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

const DragAndDropSingle = (props) => {
  const onDrop = useCallback(
    (acceptedFiles) => {
      acceptedFiles.forEach((file) => {
        const reader = new FileReader();
        reader.onload = () => {
          props.handleDrop({
            index: customFileName(10),
            name: file.name.replace(" ", ""),
            extension: file.name.split(".").pop().toLowerCase(),
            size: file.size,
            b64: file,
          });
        };
        reader.readAsDataURL(file);
      });
    },
    [props]
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: props.accept,
  });

  return (
    <section className="disLine">
      <div
        {...getRootProps({
          className: !props.transaprent ? "disCuadro" : "disCuadroTransparent",
        })}
      >
        <input {...getInputProps()} />
        <span className="disTexto">{props.children}</span>
      </div>
    </section>
  );
};

const DragAndDropList = (props) => {
  const onDrop = useCallback(
    (acceptedFiles) => {
      acceptedFiles.forEach((file) => {
        const reader = new FileReader();
        reader.onload = async () => {
          const read = {
            index: customFileName(10),
            name: file.name.replace(" ", ""),
            extension: file.name.split(".").pop().toLowerCase(),
            size: file.size,
            b64: file,
          };
          await props.handleDrop(read);
        };
        reader.readAsDataURL(file);
      });
    },
    [props]
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
  });

  return (
    <section className="disLine">
      <div {...getRootProps({ className: "disCuadro" })}>
        <input {...getInputProps()} />
        <span className="disTexto">{props.children}</span>
      </div>
    </section>
  );
};

export { DragAndDropSingle, DragAndDropList };
