import React, { useState } from "react";
import {
  fetchProgramado,
  sendUpdateFactura,
  setFilters,
} from "../actions/facturas";
import { fetchProviders } from "../actions/usuarios";
import NumberFormat from "react-number-format";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import BootstrapSwitchButton from "bootstrap-switch-button-react";
import { Toggle, Modal } from "../utils/modalUtil.jsx";
import FacturaRecord from "../components/FacturaRecord";
import "../assets/styles/components/TablePage.css";
import "../assets/styles/Filtrado.css";

import ReactExport from "react-export-excel";
import Pagination from "react-js-pagination";
import FiltroForm from "../components/FiltroForm";

const FacturasProgramadas = (props) => {
  const ExcelFile = ReactExport.ExcelFile;
  const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
  const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
  const [isLoading, SetLoading] = useState(true);
  const [facturas, SetFacturas] = useState([]);
  const [pagg, SetPagg] = useState({});
  const [Excelfacturas, SetExcelFacturas] = useState([]);

  //----> Cargando facturas Programadas
  useState(() => {
    var queryString = Object.keys(props.searchForm)
      .map((key) => {
        return (
          encodeURIComponent(key) +
          "=" +
          encodeURIComponent(props.searchForm[key])
        );
      })
      .join("&");
    props.fetchProgramado(`squer?${queryString}`, props).then((data) => {
      let mames = data;
      SetFacturas(mames.data);
      SetPagg(mames);
      SetLoading(false);
    });
  });

  const handleSubmit = (searchForm, page = "") => {
    if(searchForm === undefined){
      return;
    }
    SetLoading(false);
    var queryString = Object.keys(searchForm)
      .map((key) => {
        return (
          encodeURIComponent(key) + "=" + encodeURIComponent(searchForm[key])
        );
      })
      .join("&");
    props.setFilters(searchForm);
    props.fetchProgramado(`squer?${queryString}${page}`, props).then((data) => {
      let mames = data;
      SetFacturas(mames.data);
      delete mames.data;
      SetPagg(mames);
      SetLoading(false);
    });
  };

  const getdiasCredito = (ff, dc) => {
    let date = new Date();
    let cast = new Date(ff);
    let newDate = new Date(
      date.setTime(cast.getTime() + Number(dc) * 86400000)
    );
    return <>{newDate.toLocaleDateString("fr-CA")}</>;
  };

  const CalCredito = (ff, dc) => {
    let date = new Date();
    let cast = new Date(ff);
    let newDate = new Date(
      date.setTime(cast.getTime() + Number(dc) * 86400000)
    );
    return newDate.toLocaleDateString("fr-CA");
  };

  const downloadExcel = () => {
    var queryString = Object.keys(props.searchForm)
      .map((key) => {
        return (
          encodeURIComponent(key) +
          "=" +
          encodeURIComponent(props.searchForm[key])
        );
      })
      .join("&");
    props.setFilters(props.searchForm);
    props.fetchProgramado(`squerExcel?${queryString}`, props).then((data) => {
      SetExcelFacturas(data);
      document.getElementById("mamesno").click();
    });
  };

  const CloseCollapse = () => {
    document.getElementById("collapseFilters").click();
  };

  return (
    <div className="principal">
      <div className="text-center filter">
        <h1>General de facturas:</h1>
      </div>
      <div className="d-flex justify-content-between">
        <div>
          <button
            id="collapseFilters"
            className="btn btn-info"
            type="button"
            data-toggle="collapse"
            data-target="#collapseFilter"
            aria-expanded="false"
            aria-controls="collapseFilter"
          >
            Ver Filtros <i className="fa fa-filter"></i>
          </button>
          <button className="btn btn-success ml-3" onClick={downloadExcel}>
            <i className="fa fa-file-excel-o" aria-hidden="true"></i> Generar
            Excel
          </button>
        </div>
        <div className="btnAdd">
          <Toggle
            toggle={(show) => (
              <button
                className="btn btn-link"
                style={{ color: "white" }}
                onClick={show}
              >
                <i className="fas fa-plus"></i>
              </button>
            )}
            content={(hide) => (
              <Modal>
                <FacturaRecord handelHide={hide} handeleClose={handleSubmit} />
              </Modal>
            )}
          />
        </div>
      </div>
      <div className="collapse" id="collapseFilter">
        <FiltroForm
          ApplyFilter={(args) => handleSubmit(args)}
          Close={CloseCollapse}
        />
      </div>
      {isLoading === true ? (
        <div className="spinner-grow" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      ) : (
        <div style={{ paddingTop: "10px" }}>
          <table className="table table-sm">
            <thead className="titulotabla-sm">
              <tr>
                <th>Proveedor</th>
                <th>File</th>
                <th>File Aplicado</th>
                <th>Folio</th>
                <th>F-Factura</th>
                <th>Moneda</th>
                <th>SubTotal</th>
                <th>Iva</th>
                <th>Retencion</th>
                <th>Descuento</th>
                <th>Total</th>
                <th>Tipo cambio</th>
                <th>TotalMXN</th>
                <th>Status</th>
                <th>F-Captura</th>
                <th>Dias-Credito</th>
                <th>F-Pago</th>
                <th>Financiado</th>
                <th>F-Pagado</th>
                <th>Serie</th>
                <th>Tipo</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {facturas.map((item, i) => (
                <tr className="contenido" key={i}>
                  <td className="td-sm">{item.razonSocial}</td>
                  <td className="td-sm">{item.referencia}</td>
                  <td className="td-sm">{item.referenciaReal}</td>
                  <td className="td-sm">{item.folio}</td>
                  <td className="td-sm">{item.fechaFactura}</td>
                  <td className="td-sm">{item.moneda}</td>
                  <td className="td-sm">
                    <NumberFormat
                      displayType={"text"}
                      thousandSeparator={true}
                      prefix={"$"}
                      value={Number(item.subTotal).toFixed(2)}
                    />
                  </td>
                  <td className="td-sm">
                    <NumberFormat
                      displayType={"text"}
                      thousandSeparator={true}
                      prefix={"$"}
                      value={Number(item.iva).toFixed(2)}
                    />
                  </td>
                  <td className="td-sm">
                    <NumberFormat
                      displayType={"text"}
                      thousandSeparator={true}
                      prefix={"$"}
                      value={Number(item.retencion).toFixed(2)}
                    />
                  </td>
                  <td className="td-sm">
                    <NumberFormat
                      displayType={"text"}
                      thousandSeparator={true}
                      prefix={"$"}
                      value={Number(item.descuento).toFixed(2)}
                    />
                  </td>
                  <td className="td-sm">
                    <NumberFormat
                      displayType={"text"}
                      thousandSeparator={true}
                      prefix={"$"}
                      value={Number(item.total).toFixed(2)}
                    />
                  </td>
                  <td className="td-sm">
                    <NumberFormat
                      displayType={"text"}
                      thousandSeparator={true}
                      prefix={"$"}
                      value={Number(item.tipoCambio).toFixed(2)}
                    />
                  </td>
                  <td className="td-sm">
                    <NumberFormat
                      displayType={"text"}
                      thousandSeparator={true}
                      prefix={"$"}
                      value={Number(item.totalMXN).toFixed(2)}
                    />
                  </td>
                  <td className="td-sm">{item.estatus}</td>
                  <td className="td-sm">{item.created_at}</td>
                  <td className="td-sm">{item.user.diasCredito} Dias</td>
                  <td className="td-sm">
                    {getdiasCredito(item.created_at, item.user.diasCredito)}
                  </td>
                  <td className="td-sm">
                    <BootstrapSwitchButton
                      checked={item.financiado === "SI" ? true : false}
                      onlabel="SI"
                      offlabel="NO"
                      onChange={(cheked) => {
                        cheked === true
                          ? (item.financiado = "SI")
                          : (item.financiado = "NO");
                        props.sendUpdateFactura(
                          item,
                          "updated",
                          item.id,
                          props
                        );
                      }}
                      size="xs"
                    />
                  </td>
                  <td className="td-sm">{item.fechaPago}</td>
                  <td className="td-sm">{item.serie}</td>
                  <td className="td-sm">{item.tipoFactura}</td>
                  <td className="td-sm">
                    <Link
                      className="btn btn-link btn-sm"
                      style={{ border: 0, padding: 0 }}
                      to={`/factura/${item.id}`}
                    >
                      <i className="far fa-eye"></i>
                    </Link>
                  </td>
                </tr>
              ))}
              <tr className="contenido">
                <td colSpan="5" className="td-sm">
                  Total
                </td>
                <td className="td-sm">
                  <NumberFormat
                    displayType={"text"}
                    thousandSeparator={true}
                    prefix={"$"}
                    value={facturas
                      .reduce((total, obj) => Number(obj.subTotal) + total, 0)
                      .toFixed(2)}
                  />
                </td>
                <td className="td-sm">
                  <NumberFormat
                    displayType={"text"}
                    thousandSeparator={true}
                    prefix={"$"}
                    value={facturas
                      .reduce((total, obj) => Number(obj.iva) + total, 0)
                      .toFixed(2)}
                  />
                </td>
                <td className="td-sm">
                  <NumberFormat
                    displayType={"text"}
                    thousandSeparator={true}
                    prefix={"$"}
                    value={facturas
                      .reduce((total, obj) => Number(obj.retencion) + total, 0)
                      .toFixed(2)}
                  />
                </td>
                <td className="td-sm">
                  <NumberFormat
                    displayType={"text"}
                    thousandSeparator={true}
                    prefix={"$"}
                    value={facturas
                      .reduce((total, obj) => Number(obj.total) + total, 0)
                      .toFixed(2)}
                  />
                </td>
                <td></td>
                <td className="td-sm">
                  <NumberFormat
                    displayType={"text"}
                    thousandSeparator={true}
                    prefix={"$"}
                    value={facturas
                      .reduce((total, obj) => Number(obj.totalMXN) + total, 0)
                      .toFixed(2)}
                  />
                </td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
            </tbody>
          </table>
          <Pagination
            totalItemsCount={pagg.total}
            activePage={pagg.current_page}
            itemsCountPerPage={pagg.per_page}
            pageRangeDisplayed={20}
            prevPageText="⟨"
            firstPageText="«"
            lastPageText="»"
            nextPageText="⟩"
            innerClass="pagination"
            activeClass="page-item active"
            itemClass="page-item"
            itemClassFirst="page-item"
            itemClassPrev="page-item"
            itemClassNext="page-item"
            itemClassLast="page-item"
            linkClass="page-link"
            linkClassFirst="page-link"
            linkClassPrev="page-link"
            linkClassNext="page-link"
            linkClassLast="page-link"
            disabledClass="page-item disabled"
            onChange={(args) => handleSubmit(props.searchForm, "&page=" + args)}
          />
        </div>
      )}
      <ExcelFile
        element={
          <button style={{ display: "none" }} id="mamesno" type="button">
            Generar Excel
          </button>
        }
        filename="General Facturas"
      >
        <ExcelSheet data={Excelfacturas} name="Concentrado">
          <ExcelColumn label="F-Pagado" value="fechaPago" />
          <ExcelColumn
            label="Banco"
            value={(col) =>
              col.IdCuentaBanco === null
                ? ""
                : col.cuentaAlias + col.monedaBanco
            }
          />
          <ExcelColumn label="Cuenta" value="cuentaBanco" />
          <ExcelColumn label="F-Factura" value="fechaFactura" />
          <ExcelColumn label="RFC Emisor" value="RFCUser" />
          <ExcelColumn label="Proveedor" value="razonSocial" />
          <ExcelColumn label="Cuenta Contable MXN" value="contpaqMxn" />
          <ExcelColumn label="Cuenta Contable USD" value="contpaqUsd" />
          <ExcelColumn label="Num. Factura" value="folio" />
          <ExcelColumn label="File" value="referencia" />
          <ExcelColumn label="File Aplicado" value="referenciaReal" />
          <ExcelColumn label="F-Captura" value="created_at" />
          <ExcelColumn
            label="Dias Credito"
            value={(col) => Number(col.user.diasCredito)}
          />
          <ExcelColumn
            label="F-Pago"
            value={(col) =>
              CalCredito(col.created_at, col.user.diasCredito)
            }
          />
          <ExcelColumn label="Subtotal" value={(col) => Number(col.subTotal)} />
          <ExcelColumn label="Iva" value={(col) => Number(col.iva)} />
          <ExcelColumn
            label="Retención"
            value={(col) => Number(col.retencion)}
          />
          <ExcelColumn
            label="Descuento"
            value={(col) => Number(col.descuento)}
          />
          <ExcelColumn label="Total" value={(col) => Number(col.total)} />
          <ExcelColumn
            label="Tipo Cambio"
            value={(col) => Number(col.tipoCambio)}
          />
          <ExcelColumn
            label="Total-MXN"
            value={(col) => Number(col.totalMXN)}
          />
          <ExcelColumn label="Divisa" value="moneda" />
          <ExcelColumn
            label="tipo Cambio Pago"
            value={(col) => Number(col.tipoCambioPago)}
          />
          <ExcelColumn
            label="total Pago MXN"
            value={(col) => Number(col.totalPagoMXN)}
          />
          <ExcelColumn label="Notas" value="comentarios" />
          <ExcelColumn label="Financiado" value="financiado" />
          <ExcelColumn label="Status" value="estatus" />
          <ExcelColumn label="Tipo Factura" value="tipoFactura" />
        </ExcelSheet>
      </ExcelFile>
    </div>
  );
};

const mapStateToprops = (state) => {
  return {
    session: state.session,
    facturaGrup: state.facturaGrup,
    searchForm: state.searchForm,
  };
};

const mapDispathToProps = {
  fetchProgramado,
  sendUpdateFactura,
  fetchProviders,
  setFilters,
};

export default connect(mapStateToprops, mapDispathToProps)(FacturasProgramadas);
